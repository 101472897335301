import { ref } from '@vue/composition-api'
import store from '@/store'

export const useShopFiltersSortingAndPagination = category => {
  const filters = ref({
    q: '',
    priceRangeDefined: null,
    priceRange: [0, 100],
    categories: category || null,
    brands: null,
    ratings: null,
    page: 1,
    perPage: 15,
  })

  const filterOptions = {
    priceRangeDefined: [
      { text: 'Todo', value: null },
      { text: '<= $100', value: [0, 100] },
      { text: '$100 - $500', value: [100, 500] },
      { text: '$500 - $1000', value: [500, 1000] },
      { text: '>= $1000', value: [1000, 50000] },
    ],
    categories: [],
    brands: [],
    ratings: [
      { rating: 4, count: 160 },
      { rating: 3, count: 176 },
      { rating: 2, count: 291 },
      { rating: 1, count: 190 },
    ],
  }

  // Sorting
  const sortBy = ref({ text: 'Todo', value: null })
  const sortByOptions = [
    { text: 'Todo', value: null },
    { text: 'Menor precio', value: 'asc' },
    { text: 'Mayor precio', value: 'desc' },
  ]

  return {
    // Filter
    filters,
    filterOptions,

    // Sort
    sortBy,
    sortByOptions,
  }
}

export const useShopUi = () => {
  const itemView = 'grid-view'
  const itemViewOptions = [
    { icon: 'GridIcon', value: 'grid-view' },
    { icon: 'ListIcon', value: 'list-view' },
  ]

  // Pagination count <= required by pagination component
  const totalProducts = ref(null)

  return {
    itemView,
    itemViewOptions,
    totalProducts,
  }
}

export const useShopRemoteData = () => {
  const products = ref([])
  const fetchProducts = (...args) => store.dispatch('products/index', ...args)

  return {
    products,
    fetchProducts,
  }
}
