<template>
  <div style="height: inherit">

    <!-- ECommerce Header -->
    <section id="ecommerce-header">
      <div class="row">
        <div class="col-sm-12">
          <div class="ecommerce-header-items">
            <div class="result-toggler">

              <div
                  class="search-results d-block d-lg-none filter-heading c-hand p-1"
                  @click="mqShallShowLeftSidebar = true"
              >
                Filtrar búsqueda
              </div>
              <div class="search-results">
                {{ totalProducts }} Resultados encontrados
              </div>
            </div>
            <div class="view-options d-flex">

              <!-- Sort Button -->
              <b-dropdown
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                :text="sortBy.text"
                right
                variant="outline-primary"
              >
                <b-dropdown-item
                  v-for="sortOption in sortByOptions"
                  :key="sortOption.value"
                  @click="sortBy=sortOption"
                >
                  {{ sortOption.text }}
                </b-dropdown-item>
              </b-dropdown>

              <!-- Item View Radio Button Group  -->
              <b-form-radio-group
                v-model="itemView"
                class="ml-1 list item-view-radio-group"
                buttons
                size="sm"
                button-variant="outline-primary"
              >
                <b-form-radio
                  v-for="option in itemViewOptions"
                  :key="option.value"
                  :value="option.value"
                >
                  <feather-icon
                    :icon="option.icon"
                    size="18"
                  />
                </b-form-radio>
              </b-form-radio-group>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Overlay -->
    <div class="body-content-overlay" />

    <!-- Searchbar -->
    <div class="ecommerce-searchbar mt-1">
      <b-row>
        <b-col cols="12">
          <b-input-group class="input-group-merge">
            <b-form-input
              v-model="filters.q"
              placeholder="Búsqueda de producto"
              class="search-product"
            />
            <b-input-group-append is-text>
              <feather-icon
                icon="SearchIcon"
                class="text-muted"
              />
            </b-input-group-append>
          </b-input-group>
        </b-col>
      </b-row>
    </div>

    <!-- Products -->
    <section :class="itemView">
      <b-card
        v-for="product in products.data"
        :key="product.id"
        class="ecommerce-card"
        no-body
      >
        <div class="item-img text-center">
          <b-link
            class="d-block m-auto"
            :to="{ name: 'product-details', params: { slug: product.slug } }"
          >
            <b-img
              :alt="`${product.name}-${product.id}`"
              fluid
              loading="lazy"
              class="card-img-top m-w-img"
              :src="product.thumbnails[0]"
            />
          </b-link>
        </div>

        <!-- Product Details -->
        <b-card-body>
          <div class="item-wrapper">
            <div class="item-rating">
              <ul class="unstyled-list list-inline">
                <b-badge
                  v-if="product.qty_free_shipping === 1"
                  pill
                  variant="light-success"
                >
                  Envío gratis
                </b-badge>

                <b-badge
                  v-else
                  pill
                  variant="light-danger"
                >
                  Envío gratis a partir de {{ product.qty_free_shipping }} pzas.
                </b-badge>
              </ul>
            </div>
            <div>
              <h6 class="item-price">
                ${{ product.price }}
              </h6>
            </div>
          </div>
          <h6 class="item-name">
            <b-link
              class="text-body"
              :to="{ name: 'product-details', params: { slug: product.slug } }"
            >
              {{ product.name }}
            </b-link>
          </h6>
          <b-card-text class="item-description">
            {{ product.description }}
          </b-card-text>
        </b-card-body>

        <!-- Product Actions -->
        <div class="item-options text-center">
          <div class="item-wrapper">
            <div class="item-cost">
              <h4 class="item-price">
                ${{ product.price }}
              </h4>
            </div>
          </div>
          <b-button
            variant="primary"
            tag="a"
            class="btn-cart"
            :to="{ name: 'product-details', params: { slug: product.slug } }"
          >
            <feather-icon
              icon="EyeIcon"
              class="mr-50"
            />
            <span>Ver detalles</span>
          </b-button>
        </div>
      </b-card>
    </section>

    <!-- Pagination -->
    <section>
      <b-row>
        <b-col cols="12">
          <b-pagination
            v-model="filters.page"
            :total-rows="totalProducts"
            :per-page="filters.perPage"
            first-number
            align="center"
            last-number
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </section>

    <!-- Sidebar -->
    <portal to="content-renderer-sidebar-detached-left">
      <shop-left-filter-sidebar
        :filters="filters"
        :filter-options="filterOptions"
        :mq-shall-show-left-sidebar.sync="mqShallShowLeftSidebar"
        @reset-filters="cleanFilters"
      />
    </portal>
  </div>
</template>

<script>
import {
  BDropdown,
  BDropdownItem,
  BFormRadioGroup,
  BFormRadio,
  BRow,
  BCol,
  BInputGroup,
  BInputGroupAppend,
  BFormInput,
  BCard,
  BCardBody,
  BLink,
  BImg,
  BButton,
  BCardText,
  BPagination,
  BBadge,
} from 'bootstrap-vue'

import store from '@/store'
import Ripple from 'vue-ripple-directive'
import { onBeforeMount, ref, watch } from '@vue/composition-api'
import { useResponsiveAppLeftSidebarVisibility } from '@core/comp-functions/ui/app'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import ShopLeftFilterSidebar from './ECommerceShopLeftFilterSidebar.vue'
import { useShopFiltersSortingAndPagination, useShopUi, useShopRemoteData } from './useECommerceShop'
import { useEcommerceUi } from '../useEcommerce'

export default {
  directives: {
    Ripple,
  },
  components: {
    // BSV
    BDropdown,
    BDropdownItem,
    BFormRadioGroup,
    BFormRadio,
    BRow,
    BCol,
    BBadge,
    BInputGroup,
    BInputGroupAppend,
    BFormInput,
    BCard,
    BCardBody,
    BLink,
    BImg,
    BCardText,
    BButton,
    BPagination,

    // SFC
    ShopLeftFilterSidebar,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    category: {
      type: String,
      required: false,
    },
  },
  setup(props) {
    console.log('setup')
    const firstCall = ref({
      val: 1,
    })
    const { category } = props
    const {
      filters, filterOptions, sortBy, sortByOptions,
    } = useShopFiltersSortingAndPagination(category)

    onBeforeMount(async () => {
      await store.dispatch('products/listCategories')
      console.log(store.getters['products/listBrands'])
      console.log(store.getters['products/listCategories'])
      filterOptions.categories = store.getters['products/listCategories']
      filterOptions.brands = store.getters['products/listBrands']
      filters.value = {
        q: '',
        priceRangeDefined: null,
        priceRange: [0, 100],
        categories: category,
        brands: null,
        ratings: null,
        page: 1,
        perPage: 15,
      }
    })

    const { handleCartActionClick, toggleProductInWishlist } = useEcommerceUi()

    const {
      itemView, itemViewOptions, totalProducts,
    } = useShopUi()

    const { products, fetchProducts } = useShopRemoteData()

    const { mqShallShowLeftSidebar } = useResponsiveAppLeftSidebarVisibility()

    // Wrapper Function for `fetchProducts` which can be triggered initially and upon changes of filters
    const fetchShopProducts = () => {
      fetchProducts({
        page: filters.value.page,
        search: filters.value.q,
        sortByPrice: sortBy.value.value,
        perPage: filters.value.perPage,
        priceRange: filters.value.priceRangeDefined,
        categorization: filters.value.categories,
        properties: (filters.value.brands) ? {
          marca: filters.value.brands,
        } : null,
      })
        .then(response => {
          products.value = response.data.products
          totalProducts.value = response.data.products.total
        })
    }

    // fetchShopProducts()

    watch([filters, sortBy], () => {
      console.log('watch')
      fetchShopProducts()
    }, {
      deep: true,
    })
    return {
      // useShopFiltersSortingAndPagination
      firstCall,
      filters,
      filterOptions,
      sortBy,
      sortByOptions,

      // useShopUi
      itemView,
      itemViewOptions,
      totalProducts,
      toggleProductInWishlist,
      handleCartActionClick,
      fetchShopProducts,

      // useShopRemoteData
      products,

      // mqShallShowLeftSidebar
      mqShallShowLeftSidebar,
    }
  },
  methods: {
    cleanFilters(filters) {
      this.filters = filters
    },
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce.scss";
</style>

<style lang="scss" scoped>
.item-view-radio-group ::v-deep {
  .btn {
    display: flex;
    align-items: center;
  }
}

.m-w-img{
  max-width: 200px!important;
}
</style>
